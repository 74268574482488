
import { defineComponent, ref, computed,watch } from "vue";
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex";
import { btnPermissions } from "@/utils";

export default defineComponent({
  name: 'paymentApproveMBAFC',
  props: {},
  setup() {
    const route = useRoute()
    const subMenuTitle = computed(() => {
      if (route.name === 'paymentTrackingMBAFC' || route.name === 'paymentApproveMBAFC') {
        return 'MBAFC Payment Approve'
      } else {
        return 'MBLC Payment Approve'
      }
    })
    const activeKey = ref<string>('1')

    if (route.name === 'paymentTrackingMBAFC' || route.name === 'paymentTrackingMBLC') {
      console.log('初始化哈哈哈哈哈。。。。。。')
      activeKey.value = '2'
    } else {
      console.log('初始化ppppppp。。。。。。')
      activeKey.value = '1'
    }
    watch(()=>route.name, (newVal) => {
      console.log("初始化456789........",newVal)
      if (newVal == 'paymentApproveMBLC' || newVal == 'paymentApproveMBAFC'){
        activeKey.value = '1'
      }
     // activeKey.value = '1'
    });
    const {state} = useStore()
    const router = useRouter()
    // 点击tab
    const tabClick = (tab: string) => {
      console.log('主页面tabClick========================',route.name,tab)
      switch (tab) {
        case '1':
          console.log('点击pending。。。。')
          if (route.name == 'paymentApproveMBAFC')
          router.push({path: '/paymentApproveMBAFC,',query:{toTracking:'false'}})
          if (route.name == 'paymentApproveMBLC')
            router.push({path: '/paymentApproveMBLC',query:{toTracking:'false'}})
          if (route.name == 'paymentTrackingMBAFC')
            router.push({path: '/paymentApproveMBAFC',query:{toTracking:'false'}})
          if (route.name == 'paymentTrackingMBLC')
            router.push({path: '/paymentApproveMBLC',query:{toTracking:'false'}})
          break
        case '2':
          console.log('点击tracking。。。。')
          if (route.name == 'paymentApproveMBAFC')
          router.push({path: '/paymentApproveMBAFC/paymentTrackingMBAFC',query:{toTracking:'true'}})
          if (route.name == 'paymentApproveMBLC')
            router.push({path: '/paymentApproveMBLC/paymentTrackingMBLC',query:{toTracking:'true'}})
          break
      }
    }

    const showRouterView = computed(() => {
      switch (route.name) {
        case 'paymentApproveMBAFC':
          return btnPermissions('MBAFC PA0001', '/paymentApproveMBAFC')
        case 'paymentTrackingMBAFC':
          return btnPermissions('MBAFC PA0002', '/paymentApproveMBAFC')
        case 'paymentApproveMBLC':
          return btnPermissions('MPA0001', '/paymentApproveMBLC')
        case 'paymentTrackingMBLC':
          return btnPermissions('MPA0002', '/paymentApproveMBLC')
        default:
          return true
      }
    })


    return {
      subMenuTitle,
      activeKey,
      tabClick,
      route,
      showRouterView
    }
  }
})
